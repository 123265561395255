import AddressDTO from "@/dto/AddressDTO";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";

export default class SublimeOfficeDTO {
    public id: number | null = null;

    public title: string | null = null;

    public address: AddressDTO | null = null;

    public phone: string | null = null;

    public createDate: string | null = null;

    public updateDate: string | null = null;

    public employees: Array<SublimeEmployeeDTO> = [];
}
