















































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import RouteNames from "@/router/RouteNames";
import SalesTaxService from "@/services/SalesTaxService";
import Workspaces from "@/state/Workspaces";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import SalesTaxPermitDTO from "@/dto/salestax/SalesTaxPermitDTO";
import AdminService from "@/services/AdminService";
import {SalesTaxSubscriptionDTO, SalesTaxSubscriptionStateDTO, ScheduleRecordDTO} from "@/dto/salestax/SalesTaxSubscriptionDTO";
import {PaymentDTO} from "@/dto/payment/PaymentDTO";
import CompanyService from "@/services/CompanyService";
import SublimeOfficeDTO from "@/dto/sublime/office/SublimeOfficeDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import {SalesTaxFilingPeriod} from "@/constants/SalesTaxConstants";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import TextFieldModal from "@/components/common/TextFieldModal.vue";

const AppModule = namespace("App");

@Component({
  computed: {
    SalesTaxFilingPeriod() {
      return SalesTaxFilingPeriod
    }
  },
  components: {
    SingleFileHolder
  }
})
export default class SalesTaxSubscription extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop({default: null})
  private onClose!: (() => void) | null;

  private message = "";
  private successful = true;
  private addStateMode = false;
  private addPaymentMode = false;
  private createMode = false;

  private model = new SalesTaxSubscriptionDTO();
  private permits: Array<SalesTaxPermitDTO> = [];
  private offices: Array<SublimeOfficeDTO> = [];
  private accounts: Array<BankAccountDTO> = [];

  private newPaymentDate: Date | null = null;
  private newPaymentAmount: number | null = null;

  mounted() {
    this.loadOffices().then((any) => {
      this.loadPermits().then((any) => {
        this.loadBankAccounts().then((any) => {
          if (this.$route.name == RouteNames.SALES_TAX_SUBSCRIPTION) {
            this.loadSubscription();
            this.createMode = false;
          } else {
            this.model = new SalesTaxSubscriptionDTO();
            this.createMode = true;
          }
        })
      })
    });
  }

  loadSubscription() {
    this.startLoading();
    this.message = "";
    SalesTaxService.getSalesTaxSubscription(Number.parseInt(this.$route.params.subscriptionId)).then(
        response => {
          this.model = response.data;
          this.successful = true;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  loadPermits() {
    this.startLoading();
    this.message = "";
    return SalesTaxService.getSalesTaxPermits(Workspaces.getCurrent!.id).then(
        response => {
          this.permits = response.data;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  loadOffices() {
    this.startLoading();
    this.message = "";
    return AdminService.getAllOffices().then(
        response => {
          this.offices = response.data;
          this.successful = true;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  loadBankAccounts() {
    this.startLoading();
    this.message = "";
    return CompanyService.getBankAccounts(this.getCompanyId).then(
        response => {
          this.accounts = response.data;
          this.successful = true;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  addPayment() {
    if (this.addPaymentMode) {
      this.model.payments.push(new PaymentDTO({
        amount: this.newPaymentAmount as number,
        scheduledPaymentDate: this.newPaymentDate as Date
      }));
      this.addPaymentMode = false;
      this.newPaymentDate = null;
      this.newPaymentAmount = null;
    } else {
      this.addPaymentMode = true;
    }
  }


  addState(permit: SalesTaxPermitDTO) {
    this.model.salesTaxStates.push(new SalesTaxSubscriptionStateDTO({
      salesTaxPermit: permit
    }));
    this.addStateMode = false;
  }

  saveSubscription() {
    this.startLoading();
    this.message = "";
    const fun = this.createMode
        ? (dto: SalesTaxSubscriptionDTO) => SalesTaxService.createSalesTaxSubscription(dto)
        : (dto: SalesTaxSubscriptionDTO) => SalesTaxService.editSalesTaxSubscription(dto)
    fun(this.model).then(
        response => {
          this.successful = true;
          this.stopLoading();
          if (this.onClose) {
            this.onClose();
          } else {
            this.$router.push({name: RouteNames.SALES_TAX_SUBSCRIPTIONS, params: {companyId: this.$route.params.companyId}});
          }
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    )
  }

  get getCompanyId() {
    return Number.parseInt(this.$route.params.companyId, 10);
  }

  onProceedPaymentFromSAAccount() {
    if (this.model.proceedPaymentFromSAAccount) {
      this.model.bankAccount = null;
    }
  }

  onFilingPeriodChange(s: SalesTaxSubscriptionStateDTO) {
    s.schedule = new Array<ScheduleRecordDTO>();
    let fun: (startDate: Date, endDate: Date) => number;
    switch (s.filingPeriod) {
      case SalesTaxFilingPeriod.SEASONLY:
        fun = (startDate: Date, endDate: Date) => this.countSeasonlyFilingMonths(startDate, endDate);
        break;
      case SalesTaxFilingPeriod.QUARTERLY:
        fun = (startDate: Date, endDate: Date) => this.countQuarterlyFilingMonths(startDate, endDate);
        break;
      case SalesTaxFilingPeriod.MONTHLY:
        fun = (startDate: Date, endDate: Date) => this.countMonthlyFilingMonths(startDate, endDate);
        break;
      case SalesTaxFilingPeriod.ANNUAL:
        fun = (startDate: Date, endDate: Date) => 1;
        break;
      default:
        return;
    }
    for (let i = 0; i < fun(this.model.startDate!, this.model.endDate!); i++) {
      s.schedule.push(new ScheduleRecordDTO());
    }
  }

  countSeasonlyFilingMonths(startDate: Date, endDate: Date): number {
    let count = 0;

    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    for (let year = startYear; year <= endYear; year++) {
      if (year === startYear) {
        // Если начальный год равен конечному
        if (year === endYear) {
          for (let month = startDate.getMonth(); month <= endDate.getMonth(); month++) {
            if ([2, 5, 8, 11].includes(month)) {
              count++;
            }
          }
        } else {
          for (let month = startDate.getMonth(); month <= 11; month++) {
            if ([2, 5, 8, 11].includes(month)) {
              count++;
            }
          }
        }
      } else if (year === endYear) {
        for (let month = 0; month <= endDate.getMonth(); month++) {
          if ([2, 5, 8, 11].includes(month)) {
            count++;
          }
        }
      } else {
        count += 4; // В целом году 4 нужных месяца
      }
    }

    return count;
  }

  countQuarterlyFilingMonths(startDate: Date, endDate: Date): number {
    let count = 0;

    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    for (let year = startYear; year <= endYear; year++) {
      if (year === startYear) {
        // Если начальный год равен конечному
        if (year === endYear) {
          for (let month = startDate.getMonth(); month <= endDate.getMonth(); month++) {
            if ([0, 3, 6, 9].includes(month)) {
              count++;
            }
          }
        } else {
          for (let month = startDate.getMonth(); month <= 11; month++) {
            if ([0, 3, 6, 9].includes(month)) {
              count++;
            }
          }
        }
      } else if (year === endYear) {
        for (let month = 0; month <= endDate.getMonth(); month++) {
          if ([2, 5, 8, 11].includes(month)) {
            count++;
          }
        }
      } else {
        count += 4;
      }
    }

    return count;
  }

  countMonthlyFilingMonths(startDate: Date, endDate: Date): number {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();

    if (startYear === endYear) {
      return endMonth - startMonth + 1;
    } else {
      return (
          (endYear - startYear - 1) * 12 +
          (11 - startMonth) + 1 +
          (endMonth + 1)
      );
    }
  }

  onContractUploaded(file: FileMetaDTO) {
    this.model.contract = file;
  }

  onContractDeleted() {
    this.model.contract = undefined;
  }

  onSaveClick() {
    if (this.createMode) {
      this.saveSubscription();
    } else {
      this.showSaveModal();
    }
  }

  showSaveModal() {
    this.$modal.show(
        TextFieldModal,
        {
          onOk: (text: string) => {
            this.model.modificationJustification = text;
            this.saveSubscription();
          }
        }
    )
  }

  addSchedule(s: SalesTaxSubscriptionStateDTO) {
    s.schedule.push(new ScheduleRecordDTO());
  }

  isFile(value: string) {
    return value.startsWith('file');
  }

  getAttributes(value: string): Array<Record<string, string>> {
    const attrs = value.split(';')
        .map(attr => attr.split(':'))
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {} as Record<string, string>);
    if (value.startsWith('file')) {
      attrs['type'] = 'file';
    }
    return [attrs];
  }

}
