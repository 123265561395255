import axios, {AxiosResponse} from 'axios';
import {
    SALES_TAX_ENDPOINT,
    SALES_TAX_LIST_ENDPOINT,
    SALES_TAX_PERMIT_ENDPOINT, SALES_TAX_SCHEDULE_ENDPOINT,
    SALES_TAX_SUBSCRIPTION_ENDPOINT,
    SALES_TAX_WORKFLOW_ENDPOINT, SALES_TAX_WORKFLOW_KEY_ENDPOINT, SALES_TAX_WORKFLOW_PUBLIC_STATEMENT_ENDPOINT,
    SALES_TAX_YEARS_ENDPOINT
} from '@/constants/endpoints'
import authHeader from "@/services/auth-header";
import SalesTaxDocumentDTO from "@/dto/salestax/SalesTaxDocumentDTO";
import QueryUtils from "@/utils/QueryUtils";
import SalesTaxPermitDTO from "@/dto/salestax/SalesTaxPermitDTO";
import CreateOrUpdateSalesTaxPermitRequestDTO from "@/dto/salestax/CreateOrUpdateSalesTaxPermitRequestDTO";
import {SalesTaxSubscriptionDTO} from "@/dto/salestax/SalesTaxSubscriptionDTO";
import {SalesTaxBankStatement, SalesTaxWorkflow} from "@/dto/salestax/SalesTaxWorkflowDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import Pageable from "@/dto/Pageable";
import SalesTaxWorkflowFilter from "@/dto/salestax/SalesTaxWorkflowFilter";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import Page from "@/dto/taxation/Page";
import SalesTaxScheduleRecordDTO from "@/dto/salestax/SalesTaxScheduleRecordDTO";

class SalesTaxService {

    saveSalesTaxDocumentList(companyId: number, fileMetaIds: Array<number>) {
        return axios.post<void>(SALES_TAX_LIST_ENDPOINT, {companyId: companyId, fileMetaIds: fileMetaIds}, {headers: authHeader()});
    }

    getYearsForCompany(companyId: number) {
        const queryString = QueryUtils.buildQueryString(
            ["companyId", companyId]
        );
        return axios.get<Array<string>>(SALES_TAX_YEARS_ENDPOINT + queryString, {headers: authHeader()});
    }

    getSalesTaxDocuments(companyId: number, year: string) {
        const queryString = QueryUtils.buildQueryString(
            ["companyId", companyId],
            ["year", year]
        );
        return axios.get<Array<SalesTaxDocumentDTO>>(SALES_TAX_ENDPOINT + queryString, {headers: authHeader()});
    }

    getSalesTaxPermits(companyId: number): Promise<AxiosResponse<Array<SalesTaxPermitDTO>>>{
        const queryString = QueryUtils.buildQueryString(
            ["companyId", companyId]
        );
        return axios.get<Array<SalesTaxPermitDTO>>(SALES_TAX_PERMIT_ENDPOINT + queryString, {headers: authHeader()});
    }

    createSalesTaxPermit(request: CreateOrUpdateSalesTaxPermitRequestDTO){
        return axios.post(SALES_TAX_PERMIT_ENDPOINT, request, {headers: authHeader()});
    }

    editSalesTaxPermit(permitId: number, request: CreateOrUpdateSalesTaxPermitRequestDTO){
        return axios.put(`${SALES_TAX_PERMIT_ENDPOINT}/${permitId}`, request, {headers: authHeader()});
    }

    getSalesTaxSubscriptions(companyId: number) {
        const queryString = QueryUtils.buildQueryString(
            ["companyId", companyId]
        );
        return axios.get<Array<SalesTaxSubscriptionDTO>>(`${SALES_TAX_SUBSCRIPTION_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    getSalesTaxSubscription(id: number){
        return axios.get<SalesTaxSubscriptionDTO>(`${SALES_TAX_SUBSCRIPTION_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    createSalesTaxSubscription(request: SalesTaxSubscriptionDTO){
        return axios.post<void>(SALES_TAX_SUBSCRIPTION_ENDPOINT, request, {headers: authHeader()});
    }

    editSalesTaxSubscription(request: SalesTaxSubscriptionDTO){
        return axios.put<void>(`${SALES_TAX_SUBSCRIPTION_ENDPOINT}/${request.id}`, request, {headers: authHeader()});
    }

    getWorkflows(filter: SalesTaxWorkflowFilter){
        const queryString = QueryUtils.objectToQueryString(filter);
        return axios.get<Page<SalesTaxWorkflow>>(SALES_TAX_WORKFLOW_ENDPOINT + queryString, {headers: authHeader()});
    }

    getWorkflowByKey(key: string){
        return axios.get<SalesTaxWorkflow>(`${SALES_TAX_WORKFLOW_KEY_ENDPOINT}/${key}`);
    }

    saveRequestedBankStatement(key: string,id: number, file: FileMetaDTO){
        return axios.put<SalesTaxWorkflow>(`${SALES_TAX_WORKFLOW_KEY_ENDPOINT}/${key}/statement/${id}`, file)
    }

    saveNewBankStatements(key: string, files: Array<FileMetaDTO>){
        return axios.post<SalesTaxWorkflow>(`${SALES_TAX_WORKFLOW_KEY_ENDPOINT}/${key}/statement`, files)
    }

    requestAdditionalStatement(workflowId: number, description: string){
        return axios.post<SalesTaxBankStatement>(`${SALES_TAX_WORKFLOW_ENDPOINT}/${workflowId}/statement`, {description: description}, {headers: authHeader()});
    }

    assignEmployeeToWorkflow(workflowId: number, assignee: SublimeEmployeeDTO) {
        return axios.put<void>(`${SALES_TAX_WORKFLOW_ENDPOINT}/${workflowId}/assignee`, assignee, {headers: authHeader()});
    }

    changeStatementStatus(workflowId: number, statementId: number, status: string){
        return axios.put<void>(`${SALES_TAX_WORKFLOW_ENDPOINT}/${workflowId}/statement/${statementId}`, {status: status}, {headers: authHeader()});
    }

    editWorkflow(workflow: SalesTaxWorkflow){
        return axios.put<void>(`${SALES_TAX_WORKFLOW_ENDPOINT}/${workflow.id}`, workflow, {headers: authHeader()});
    }

    getWorkflow(id: number){
        return axios.get<SalesTaxWorkflow>(`${SALES_TAX_WORKFLOW_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    approveOrRejectWorkflow(key: string, approve: boolean, rejectionReason: string){
        return axios.put<void>(`${SALES_TAX_WORKFLOW_KEY_ENDPOINT}/${key}/approve`, {approve: approve, rejectionReason: rejectionReason});
    }

    getSchedule(hideCreated: boolean, pageNumber: number, pageSize: number){
        const queryString = QueryUtils.buildQueryString(
            ["hideCreated", hideCreated],
            ["pageNumber", pageNumber],
           [ "pageSize", pageSize]
        )
        return axios.get<Page<SalesTaxScheduleRecordDTO>>(`${SALES_TAX_SCHEDULE_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    createWorkflows(ids: Array<number>){
        return axios.post(`${SALES_TAX_SCHEDULE_ENDPOINT}`, {scheduleIds: ids},{headers: authHeader()});
    }

}

export default new SalesTaxService();