import {PaymentDTO} from "@/dto/payment/PaymentDTO";
import {SalesTaxFilingPeriod, SalesTaxSubscriptionField} from "@/constants/SalesTaxConstants";
import SalesTaxPermitDTO from "@/dto/salestax/SalesTaxPermitDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";
import SublimeOfficeDTO from "@/dto/sublime/office/SublimeOfficeDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {SalesTaxWorkflow} from "@/dto/salestax/SalesTaxWorkflowDTO";

export class SalesTaxSubscriptionDTO {
    id?: number;
    contractNumber?: string;
    company?: CompanyDTO;
    createDate?: Date;
    updateDate?: Date;
    startDate?: Date;
    endDate?: Date;
    contract?: FileMetaDTO;
    office: SublimeOfficeDTO = new SublimeOfficeDTO();
    bankAccount?: BankAccountDTO | null;
    proceedPaymentFromSAAccount = false;
    salesTaxStates: Array<SalesTaxSubscriptionStateDTO> = [];
    payments: Array<PaymentDTO> = [];
    modificationJustification?: string;
    changeLog: Array<SalesTaxLogEventDTO> = [];

    constructor(init?: Partial<SalesTaxSubscriptionStateDTO>) {
        Object.assign(this, init);
    }
}

export class SalesTaxSubscriptionStateDTO {
    id?: number;
    createDate?: Date;
    updateDate?: Date;
    filingPeriod?: SalesTaxFilingPeriod
    salesTaxPermit!: SalesTaxPermitDTO;
    schedule: Array<ScheduleRecordDTO> = [];

    constructor(init?: Partial<SalesTaxSubscriptionStateDTO>){
        Object.assign(this, init);
    }
}

export class ScheduleRecordDTO {
    id?: number;
    plannedDate?: Date;
    stateDeadline?: Date;
    workflow?: SalesTaxWorkflow;
}

export class SalesTaxLogEventDTO {
    editor!: SublimeEmployeeDTO;
    justification!: string;
    records: Array<SalesTaxLogRecordDTO> = [];
    createDate!: Date;
}

export class SalesTaxLogRecordDTO {
    state: string | null = null;
    field!: SalesTaxSubscriptionField;
    previousValue: string | null = null;
    newValue: string | null = null;
}