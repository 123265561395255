













import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class TextFieldModal extends Vue {

  private text = "";

  @Prop({default: 4})
  rows!: number;

  @Prop({default: null})
  onClose!: () => void | null

  @Prop()
  onOk!: (text: string) => void;

  cancel(){
    if(this.onClose){
      this.onClose()
    }
    this.$modal.hideAll();
  }

  ok(){
    this.onOk(this.text);
    this.$modal.hideAll();
  }

}
